<template>
  <div>
    <transition name="fade-fastest" mode="out-in">
      <welcome-back
        v-if="showCustomerRecognisedScreen"
        :customer-full-name="customerName"
        :reward-balance="rewardBalance"
      />
      <div v-else-if="playRecommendedServicesAnimation" class="recommended-services-animation-wrapper">
        <div class="icon-wrapper">
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.4347 6.27737C10.4087 6.27737 11.1956 7.07183 11.1956 8.05518V8.94408L14.9925 7.98851C17.089 7.46073 19.3011 7.70517 21.2381 8.68297C23.7858 9.97188 26.7848 9.97188 29.3326 8.68297L29.8608 8.4163C30.9944 7.83851 32.3261 8.67186 32.3261 9.94965V25.4888C32.3261 26.2277 31.8693 26.8944 31.1815 27.1555L29.272 27.8777C26.7298 28.8388 23.9014 28.6888 21.4747 27.4666C19.3891 26.411 17.001 26.1499 14.7338 26.7221L11.1956 27.611V32.9445C11.1956 33.9278 10.4087 34.7223 9.4347 34.7223C8.46072 34.7223 7.67383 33.9278 7.67383 32.9445V8.05518C7.67383 7.07183 8.46072 6.27737 9.4347 6.27737ZM11.1956 16.672L14.7173 15.8997V19.5387L11.1956 20.3109V23.9443L13.8809 23.2665C14.1615 23.1943 14.4367 23.1332 14.7173 23.0832V19.5387L16.8579 19.072C17.3146 18.972 17.7768 18.9331 18.2391 18.9553V15.3997C18.9874 15.422 19.7358 15.5442 20.4622 15.7553L21.7608 16.1386V19.8443L19.4662 19.1609C19.0645 19.0442 18.6518 18.972 18.2391 18.9498V22.9165C19.4387 23.0221 20.6218 23.2888 21.7608 23.7165V19.8443L23.0099 20.2165C23.7528 20.4387 24.5122 20.572 25.2826 20.6276V17.0553C24.8534 17.0109 24.4241 16.9275 24.0059 16.8053L21.7608 16.1386V12.6941C21.0455 12.483 20.3411 12.2052 19.6588 11.8608C19.2076 11.633 18.7288 11.4719 18.2391 11.3719V15.3942C17.5237 15.372 16.8084 15.4386 16.1095 15.5942L14.7173 15.8997V11.7219L11.1956 12.6108V16.672ZM25.2826 24.9277C26.207 25.011 27.148 24.8888 28.0339 24.5499L28.8043 24.261V20.272L28.3696 20.372C27.3571 20.6109 26.3171 20.6887 25.2826 20.622V24.9277ZM28.8043 16.6275V12.6886C27.6542 13.0275 26.4712 13.1941 25.2826 13.1941V17.0553C26.0474 17.1331 26.8233 17.0831 27.5772 16.9109L28.8043 16.622V16.6275Z"
              fill="#2D3139"
            />
          </svg>
        </div>
        <div class="text-header-1 mb-8px">Need a pit stop?</div>
        <div class="text-body-4">
          Hey {{ customerName }}, looks like your vehicle is also due for a few services since we saw you last.
        </div>
      </div>
      <div v-else-if="showDeferredJobs" key="deferred_services" class="select-vehicle-screen">
        <div class="text-header-4 mb-12px">Add additional services below</div>
        <div class="deferred-jobs-list">
          <deferred-job-card
            v-for="job in selectedRecognisedVehicle.deferred_jobs"
            :key="job.id"
            :deferred-job="job"
            :selected="isDeferredJobSelected(job)"
            @click.native="handleDeferredJobClick(job)"
          />
        </div>
        <div class="text-body-5 mt-12px">
          Prices quoted previously may be subject to change. Including additional services in your appointment may
          change the appointment duration.
        </div>
        <transition name="fade-faster">
          <div class="sticked-to-bottom-button-wrapper">
            <div class="content-container">
              <button class="primary-btn full-width" @click.prevent="handleBookNowBtnClicked">
                {{ !showBookNowButton ? 'Next' : 'Book now' }}
              </button>
            </div>
          </div>
        </transition>
      </div>
      <div v-else key="vehicle_screen" class="select-vehicle-screen">
        <div v-if="hasHistory">
          <el-collapse-transition>
            <recognised-customer-garage
              v-if="showCustomerGarage"
              :vehicles="recognisedVehicles"
              :selected-vehicle-slug="selectedRecognisedVehicle?.slug"
              @vehicle-selected="(vehicle) => (selectedRecognisedVehicle = vehicle)"
            />
          </el-collapse-transition>
          <button class="add-vehicle-button" @click.prevent="toggleShowAddNewVehicle">
            <plus-icon v-if="showCustomerGarage" />
            <car-icon v-else />
            <span>
              {{ showCustomerGarage ? 'Add new vehicle' : 'View all vehicles' }}
            </span>
          </button>
        </div>
        <transition name="fade-faster">
          <div
            v-if="!hasHistory || (hasHistory && showAddNewVehicle)"
            class="select-vehicle-data-dropdowns-wrapper"
            :class="{ 'spacing-top': hasHistory }"
          >
            <select-vehicle-data-custom-dropdown
              title="Select vehicle year"
              options-entity="year"
              :options="availableYears"
              :selected-option="year"
              :disabled="!availableYears.length"
              :min-value="shopMinYearAllowed"
              :min-value-error-text="`Sorry! We don't work on vehicles older than ${shopMinYearAllowed}`"
              @option-selected="handleYearSelected"
              @deselect-option="handleYearDeselected"
            />
            <select-vehicle-data-custom-dropdown
              id="selectMakeDropdown"
              title="Select vehicle make"
              options-entity="make"
              :with-search="true"
              :options="availableMakes"
              :selected-option="make"
              :disabled="!availableMakes.length || !year"
              @option-selected="handleMakeSelected"
              @deselect-option="handleMakeDeselected"
            />
            <select-vehicle-data-custom-dropdown
              id="selectModelDropdown"
              title="Select vehicle model"
              options-entity="model"
              :with-search="true"
              :options="availableModels"
              :selected-option="model"
              :disabled="!year || !make"
              @option-selected="handleModelSelected"
              @deselect-option="handleModelDeselected"
            />
            <div class="form-group" style="width: 100%">
              <label class="form-label"> License plate <span class="text-gray-500">(optional)</span> </label>
              <el-input :maxlength="8" v-model="licensePlate" />
            </div>
          </div>
        </transition>
        <transition name="fade-faster">
          <div v-if="selectedRecognisedVehicle || (year && make && model)" class="sticked-to-bottom-button-wrapper">
            <div class="content-container">
              <button class="primary-btn full-width" @click.prevent="handleBookNowBtnClicked">
                {{
                  (selectedRecognisedVehicle && selectedRecognisedVehicle?.deferred_jobs?.length) || !showBookNowButton
                    ? 'Next'
                    : 'Book now'
                }}
              </button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import WelcomeBack from './WelcomeBack';
import RecognisedCustomerGarage from './RecognisedCustomerGarage';
import SelectVehicleDataCustomDropdown from './SelectVehicleDataCustomDropdown';
import DeferredJobCard from './DeferredJobCard';
import VehiclesService from '@/services/VehiclesService';
import PlusIcon from './PlusIcon';
import CarIcon from './CarIcon';
import ShopServicesService from '@/services/ShopServicesService';

export default {
  name: 'SelectVehicleStep',
  props: {
    showCustomerRecognisedScreen: {
      type: Boolean,
      required: false,
    },
    customerName: {
      type: String,
      default: '',
    },
    accountSlug: {
      type: String,
      required: true,
    },
    recognisedVehicles: {
      type: Array,
      default: () => [],
    },
    isAddAdditionalServicesOptionEnabled: {
      type: Boolean,
      default: false,
    },
    showDeferredJobs: {
      type: Boolean,
      default: false,
    },
    showBookNowButton: {
      type: Boolean,
      default: false,
    },
    rewardBalance: {
      type: String,
    },
  },
  components: {
    WelcomeBack,
    RecognisedCustomerGarage,
    SelectVehicleDataCustomDropdown,
    PlusIcon,
    CarIcon,
    DeferredJobCard,
  },
  data() {
    return {
      showWelcomeBack: false,
      selectedRecognisedVehicle: null,
      showCustomerGarage: true,
      showAddNewVehicle: false,
      availableYears: [],
      year: '',
      availableMakes: [],
      make: '',
      availableModels: [],
      model: '',
      licensePlate: '',
      selectedDeferredJobs: [],
      shopMinYearAllowed: 1980,
      playRecommendedServicesAnimation: false,
    };
  },
  async mounted() {
    this.shopMinYearAllowed = parseInt(
      (await ShopServicesService.get.shopExtraInfo(this.accountSlug)).data.data.min_year_bookings,
    );

    return Promise.all([this.fetchYears(), this.fetchMakes()]);
  },
  watch: {
    accountSlug() {
      this.fetchMakes();
    },
  },
  computed: {
    hasHistory() {
      return this.recognisedVehicles.length > 0;
    },
  },
  methods: {
    async fetchYears() {
      const {
        data: {
          data: { min_year, max_year },
        },
      } = await VehiclesService.get.years();

      const availableYears = [];
      for (let i = +max_year; i >= +min_year; i--) {
        availableYears.push(i);
      }

      this.availableYears = availableYears;
    },
    async fetchMakes() {
      const {
        data: { data: accountScopedMakes },
      } = await VehiclesService.get.accountScopedMakes(this.accountSlug);

      this.availableMakes = accountScopedMakes.filter(({ logo }) => logo);
    },
    async fetchModels() {
      if (!this.year || !this.make) return;

      const {
        data: { data: availableModels },
      } = await VehiclesService.get.models(this.make.id);
      this.availableModels = availableModels;
    },

    async toggleShowAddNewVehicle() {
      if (this.showCustomerGarage) {
        this.selectedRecognisedVehicle = null;
        this.showCustomerGarage = false;
        await new Promise((resolve) => setTimeout(() => resolve(true), 300));
        this.showAddNewVehicle = true;
        return;
      }

      this.year = null;
      this.make = null;
      this.model = null;
      this.showAddNewVehicle = false;
      await new Promise((resolve) => setTimeout(() => resolve(true), 250));
      this.showCustomerGarage = true;
    },

    async handleYearSelected(year) {
      this.year = year;
      const nextDropdownWrapperEl = document.querySelector('#selectMakeDropdown .dropdown-header');

      await new Promise((resolve) => setTimeout(() => resolve(true), 150));
      nextDropdownWrapperEl.click();
    },
    handleYearDeselected() {
      this.year = null;
      if (this.make) this.make = null;
      if (this.model) this.model = null;
    },

    async handleMakeSelected(make) {
      this.make = make;
      await this.fetchModels();

      const nextDropdownWrapperEl = document.querySelector('#selectModelDropdown .dropdown-header');

      await new Promise((resolve) => setTimeout(() => resolve(true), 150));
      nextDropdownWrapperEl.click();
    },
    handleMakeDeselected() {
      this.make = null;
      if (this.model) this.model = null;
    },

    handleModelSelected(model) {
      this.model = model;
    },
    handleModelDeselected() {
      this.model = null;
    },

    isDeferredJobSelected(job) {
      return !!this.selectedDeferredJobs.find((defJob) => defJob.id === job.id);
    },

    handleDeferredJobClick(job) {
      if (this.isDeferredJobSelected(job)) {
        this.$posthog.capture('deferred_jobs', { action: 'remove' });
        this.selectedDeferredJobs = this.selectedDeferredJobs.filter((defJob) => defJob.id !== job.id);
        return;
      }

      this.$posthog.capture('deferred_jobs', { action: 'add' });
      this.selectedDeferredJobs.push(job);
    },

    async handleBookNowBtnClicked() {
      if (
        this.selectedRecognisedVehicle &&
        this.selectedRecognisedVehicle.deferred_jobs.length &&
        this.isAddAdditionalServicesOptionEnabled &&
        !this.showDeferredJobs
      ) {
        this.playRecommendedServicesAnimation = true;
        await new Promise((resolve) => setTimeout(() => resolve(true), 1500));
        this.playRecommendedServicesAnimation = false;
        this.$emit('display-deferred-jobs');
        this.$posthog.capture('deferred_jobs', { action: 'show' });
        return;
      }

      if (this.selectedRecognisedVehicle) {
        const { year, make, model } = this.selectedRecognisedVehicle;
        this.$posthog.capture('vehicleSelection_click_existingVehicle');
        this.$posthog.capture('deferred_jobs', {
          category: 'existing_vehicle',
          total_deferred_jobs: this.selectedDeferredJobs.length,
        });
        return this.$emit('vehicle-selected', {
          year,
          make,
          model,
          recognisedVehicleExternalId: this.selectedRecognisedVehicle.external_id || null,
          licensePlate: this.licensePlate,
          deferredJobs: this.selectedDeferredJobs,
        });
      } else {
        this.$posthog.capture('vehicleSelection_click_addVehicle');
        this.$posthog.capture('deferred_jobs', {
          category: 'add_vehicle',
          total_deferred_jobs: this.selectedDeferredJobs.length,
        });
        return this.$emit('vehicle-selected', {
          year: this.year,
          make: this.make.name,
          model: this.model.name,
          licensePlate: this.licensePlate,
          deferredJobs: this.selectedDeferredJobs,
          recognisedVehicleExternalId: null,
        });
      }
    },
  },
};
</script>
